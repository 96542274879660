* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body, #root, .App {
    height: 100vh;
    width: 100vw;
    display: grid;
    justify-items: center;
    grid-template-rows: auto 1fr;
}

button {
    cursor: pointer;
    outline: none;
    border: none;
    user-select: none;
    /* Non-prefixed version, currently */
}

img {
    user-select: none;
    /* Non-prefixed version, currently */
    -webkit-user-drag: none;
}

button:active {
    opacity: 0.8;
}

select {
    appearance: none;
}

img {
    -webkit-user-drag: none;
}