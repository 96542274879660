.TableHeader {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-right: 40px;
  background: rgba(47, 68, 84, 0.2);
}

.TableHeader * {
  margin-right: 20px;
}

.TableHeader div {
  font-weight: 500;
  font-size: 18px;
  padding: 0 10px
}

.Changed {
  margin-left: 40px;
  margin-right: 20px;
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0);
}

.CheckBox {
  display: inline-block;
  margin-right: 20px;
  width: 23px;
  height: 23px;
}

.CheckBox input[type="checkbox"] {
  display: none;
}

.CheckBox input[type="checkbox"]+span::before {
  content: '';
  display: inline-block;
  width: 23px;
  height: 23px;
  border: 3px solid rgb(47, 68, 84);
  border-radius: 5px;
}

.MakeActive {
  width: 119px;
  min-width: 119px;
}