.Wrapper {
    position: relative;
    height: max-content;
    margin-right: 20px;
}

.Wrapper:hover .ToolTip {
    display: grid;
}

.ToolTip * {
    margin: 0;
}

.ToolTip {
    display: none;
    grid-template-columns: max-content auto;
    align-items: center;
    position: absolute;
    bottom: -55px;
    right: 0px;
    width: max-content;
    padding: 0 8px;
    height: 40px;
    line-height: 40px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    z-index: 1;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.251));
    font-size: 16px;
    animation: from-center 200ms ease;
}

@keyframes from-center {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}

.ToolTip::before {
    content: '';
    position: absolute;
    top: -15px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent rgb(255, 255, 255) transparent;
}

.ToolTip img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}