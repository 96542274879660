.Modal {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.659);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.CreateOrUpdateEventForm {
    position: relative;
    display: grid;
    grid-row-gap: 20px;
    justify-content: center;
    align-content: center;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 50px;
}

.Close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
}

.Input input, select {
    border: 2px solid rgb(209, 209, 209);
    height: 40px;
}

.StartDate {
    grid-column: 1;
    grid-row: 1;
}

.StartTime {
    grid-column: 2;
    grid-row: 1;
}

.EndTime {
    grid-column: 3;
    grid-row: 1;
}

.Location {
    grid-column: 1/4;
    grid-row: 2;
}

.CourseName {
    grid-column: 1/4;
    grid-row: 3;
}

.Create {
    grid-column: 2;
    grid-row: 4;
}