.ContentManagement {
    width: 95vw;
    max-width: 1600px;
    height: Calc(100vh - 173px);
    margin-top: -158px;
}

.Tab {
    width: 500px;
    height: max-content;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.TabButtons {
    background: rgb(54, 74, 88);
    border-radius: 6px;
    border: none;
    color: rgb(193, 193, 193);
    font-weight: 500;
    font-size: 17px;
    width: 156px;
    height: 38px;
    outline: none;
}

.ActiveButton {
    background: rgb(255, 255, 255);
    color: rgb(47, 68, 84);
}

.Body {
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    border-radius: 10px;
}