.Input{
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 15px;
    color: #444444;
    background: transparent;
    outline: none;
    border: 2px solid #d1d1d100;
}
.Input:focus{
    border: 2px solid #d1d1d1;
}

.Input:disabled{
    background: #ececec79;
    cursor: not-allowed;
}

.Error{
    border: 3px solid #ff6b6b !important;
}