.Table {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.Header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr auto auto 29px;
  grid-gap: 10px;
  width: 100%;
  height: max-content;
  margin-bottom: 10px;
}

.TableTitle {
  grid-column: 1/5;
  grid-row: 1/3;
  line-height: 100px;
  height: 100px;
  font-weight: bold;
  font-size: 31px;
  color: rgb(47, 68, 84);
  padding-left: 50px;
}

.Delete {
  grid-column: 2;
  grid-row: 2;
}

.Update {
  grid-column: 3;
  grid-row: 2;
}

.TableBody {
  grid-row: 3;
  grid-column: 1;
  width: 100%;
  height: 100%;
  /* overflow: hidden auto; */
}

.Loading {
  grid-row: 2/4;
  grid-column: 1;
}

.Add {
  position: sticky;
  bottom: 10px;
  right: 10px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: rgb(47, 68, 84);
  color: rgb(255, 255, 255);
  display: grid;
  font-size: 60px;
  font-weight: 300;
  z-index: 10;
  grid-column: 1;
  grid-row: 1/4;
  justify-self: end;
  align-self: end;
}

.Empty {
  grid-row: 2/4;
  grid-column: 1;
  font-weight: bold;
  font-size: 31px;
  justify-self: center;
  align-self: center;
}
