.Header {
    grid-row: 1;
    grid-column: 1;
    align-self: flex-start;
    display: grid;
    grid-template-columns: 1fr minmax(auto, 800px) minmax(auto, 800px) 1fr;
    width: 100vw;
    height: 273px;
    padding: 0px 2.5vw;
}

.Logo {
    grid-column: 2;
    justify-self: start;
    width: 125px;
    height: 78px;
    margin-top: 10px;
}

.LogOut {
    grid-column: 3;
    overflow: hidden;
    position: relative;
    justify-self: end;
    display: flex;
    align-items: center;
    font-size: 25px;
    color: rgba(255, 255, 255);
    text-decoration: none;
    width: 58px;
    padding: 5px;
    height: 58px;
    background: rgb(70, 70, 70);
    border-radius: 50px;
    border: 0px;
    text-align: center;
    margin-top: 10px;
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.42);
    transition: width 700ms ease, padding 700ms ease, color 700ms ease;
}

.Avatar {
    position: absolute;
    left: 0;
    width: 58px;
    height: 58px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 58px;
    background: rgb(70, 70, 70);
    box-shadow: none;
    border-radius: 50px;
    border: none;
    transition: transform 700ms ease, box-shadow 700ms ease;
}

.LogOut:hover {
    width: 165px;
    padding-right: 20px;
    padding-left: 68px;
    color: rgb(255, 255, 255);
}

.LogOut:hover .Avatar {
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.251);
    transform: rotate(-360deg)
}

.signedIn {
    background: linear-gradient(180deg, rgb(33, 48, 60) 273px, rgb(241, 241, 241) 273px);
}

.signedOut {
    background: rgb(33, 48, 60);
}