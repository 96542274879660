.Button{
    width: 119px;
    height: 41px;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Button img{
    margin-right: 10px;
}

.Button:disabled{
    opacity: 0.7;
    cursor: not-allowed;
}

.Delete{
    background: #d04e4e;
}

.InProgress.Delete {
  background: linear-gradient(-45deg, #ffffff99 25%, transparent 25%, transparent 50%, #ffffff99 50%, #ffffff99 75%, transparent 75%, transparent), linear-gradient(to top, #d04e4e, #d04e4e);
  background-size: 40px 40px, 100% 100%;
  animation: progress-bar-stripes 600ms linear infinite;
}

.Update{
    background: #2F4454;
}

.InProgress.Update {
  background: linear-gradient(-45deg, #ffffff99 25%, transparent 25%, transparent 50%, #ffffff99 50%, #ffffff99 75%, transparent 75%, transparent), linear-gradient(to top, #2F4454, #2F4454);
  background-size: 40px 40px, 100% 100%;
  animation: progress-bar-stripes 600ms linear infinite;
}

@keyframes progress-bar-stripes {
  from { background-position: 0 0; }
  to { background-position: 40px 0; }
}
