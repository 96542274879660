.Wrapper {
    position: absolute;
    width: 100%;
    height: max-content;
    margin-top: 10px;
}

.AlertMessage {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    width: max-content;
    height: 40px;
    background: #2F4454;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    color: #FFF;
    padding: 0px 15px;
    font-size: 13px;
    font-weight: 400;
    margin: auto;
}

.Icon {
    width: 25px;
    height: 25px;
}

.Close {
    width: 15px;
    height: 15px;
}

.Close:active {
    opacity: 0.8;
}