.Modal {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.337);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.DialogBox {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-gap: 10px;
    justify-content: center;
    align-content: center;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 30px;
    color: rgb(0, 0, 0);
    width: 550px;
    height: 225px;
    animation: from-center 400ms ease;
}

@keyframes from-center {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}

.Icon {
    grid-column: 1;
    grid-row: 1/3;
    width: 100px;
    height: 100px;
}

.Title {
    grid-column: 2/4;
    grid-row: 1;
    font-size: 20px;
    font-weight: 600;
    height: 30px;
    overflow: hidden auto;
}

.Message {
    grid-column: 2/4;
    grid-row: 2;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden auto;
}

.OkButton {
    justify-self: flex-end;
    grid-column: 3;
    grid-row: 3;
    font-weight: 600;
    background: rgb(0, 0, 0);
    width: 120px;
    height: 32px;
}

.CancelButton {
    justify-self: flex-end;
    grid-column: 2;
    grid-row: 3;
    font-weight: 600;
    background: rgb(0, 0, 0);
    width: 120px;
    height: 32px;
}