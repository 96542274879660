.TableRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.122);
  padding-right: 29px;
  transition: background-color 1s ease-in
}

.Flash {
  background-color: rgb(255, 247, 229);
}

.TableRow:hover {
  background: rgba(242, 242, 242, 0.616);
}

.TableRow:last-of-type {
  border-bottom: none;
}

.Changed {
  margin-left: 40px;
  margin-right: 20px;
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(48, 69, 85);
}

.Hide {
  margin-left: 40px;
  margin-right: 20px;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0);
}

.CheckBox {
  margin-right: 20px;
  display: inline-block;
  width: 23px;
  height: 23px;
}

.CheckBox input[type="checkbox"] {
  display: none;
}

.CheckBox span {
  display: inline-block;
  width: 23px;
  height: 23px;
}

.CheckBox input[type="checkbox"]+span::before {
  content: '';
  display: inline-block;
  width: 23px;
  height: 23px;
  border: 3px solid rgb(47, 68, 84);
  border-radius: 5px;
}

.CheckBox input[type="checkbox"]:checked+span::before {
  content: url('../../icons/Check.svg');
  display: inline-block;
  width: 23px;
  height: 23px;
}

.MakeActive {
  width: 119px;
  min-width: 119px;
  height: 41px;
  background: rgb(73, 168, 88);
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 17px;
  color: rgb(255, 255, 255);
  opacity: 0;
}

.TableRow:hover .MakeActive {
  opacity: 1;
}